ul {
  list-style: none;
}

.ais-Pagination-link,
.ais-RatingMenu-link {
  color: black;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.ais-Breadcrumb-link:hover,
.ais-Breadcrumb-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-HierarchicalMenu-link:focus,
.ais-Menu-link:hover,
.ais-Menu-link:focus,
.ais-Pagination-link:hover,
.ais-Pagination-link:focus,
.ais-RatingMenu-link:hover,
.ais-RatingMenu-link:focus {
  color: black;
}

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: 0.3rem;
}

.ais-Pagination-link {
  padding: 0.3rem 0.6rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.ais-Pagination-link:hover,
.ais-Pagination-link:focus {
  background-color: #e3e5ec;
}
.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: 0.6;
  cursor: not-allowed;
  color: #a5abc4;
}
.ais-Pagination-item--disabled .ais-Pagination-link:hover,
.ais-Pagination-item--disabled .ais-Pagination-link:focus {
  color: #a5abc4;
  background-color: #fff;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: black;
  border-color: black;
}
.ais-Pagination-item--selected .ais-Pagination-link:hover,
.ais-Pagination-item--selected .ais-Pagination-link:focus {
  color: #fff;
}

// customizing algolia search box
.ais {
  &-Searchbox {
    &-input {
      width: 100%;
      height: 2.5rem;
      padding: 1rem;
      border: 2px solid var(--chakra-colors-gray-300);
    }
  }

  &-Highlight-highlighted {
    background-color: yellow;
  }

  &-Pagination {
    display: flex;
    flex-grow: 1;
    margin: 2rem 0;

    &-list {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &-Hits {
    display: flex;
    flex-grow: 1;
    width: 100%;

    &-list {
      list-style: none;
      flex-grow: 1;
    }
  }

  &-HierarchicalMenu {
    &-list {
      &--child {
        margin: 0.2rem 0;
        padding-left: 1rem;

        .ais-HierarchicalMenu-link {
          background-color: black;
          color: white;
          .ais-HierarchicalMenu-labelText {
            font-weight: 400;
            margin-right: 0.5rem;
          }
          .ais-HierarchicalMenu-count {
            background-color: lightgray;
            padding: 0.2rem 0.4rem;
            border-radius: 0.6rem;
            color: black;
          }
        }
      }
    }

    &-item {
      margin: 0.8rem 0;
    }
    &-link {
      background-color: white;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      margin: 1rem 0;
    }
    &-labelText {
      font-weight: 600;
      margin-right: 0.5rem;
    }
    &-count {
      background-color: lightgray;
      padding: 0.2rem 0.4rem;
      border-radius: 0.6rem;
    }
  }

  &-RefinementList {
    &-list {
    }

    &-item {
      margin: 0.3rem 0;
    }
    &-labelText {
      margin-right: 0.5rem;
    }

    &-checkbox {
      margin-right: 0.5rem;
    }

    &-labelText {
    }

    &-count {
      background-color: lightgray;
      padding: 0.1rem 0.4rem;
      border-radius: 0.6rem;
      font-size: 0.8rem;
      font-weight: 700;
    }

    &-showMore {
      color: var(--chakra-colors-gray-500);
      font-size: 0.75rem;
      &::after {
        content: "...";
      }
    }
  }

  &-SearchBox-input {
    margin-top: 0.5rem;
    height: 1.5rem;
    border: unset;
    border-bottom: 1px solid var(--chakra-colors-gray-500);
    background-color: transparent;
    border-radius: 0 !important;
    padding-bottom: 0.2rem;
  }
}
