.wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  // background-color: rgb(246,248,250);
}

.with-sidebar {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  align-items: stretch;
  flex: 1 0 auto;
  min-height: 80vh;
  max-width: 100vw;
  // background-color: #f7fafcba; // height: 100vh;
  background-color: white; // height: 100vh;
  &__sidebar {
    &__logo {
      margin: 2rem 2rem 0rem 2rem;
      height: 3rem;
    }
    &__divider {
      margin-top: 1.5rem !important;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
    }

    &__navItem {
      // margin: 0 0 0.5rem 0;
      padding: 0.1rem 1rem;
      display: flex;
      align-items: center;

      &:hover {
        // background-color: var(--chakra-colors-gray-100);
      }

      &__active {
        // background-color: var(--chakra-colors-gray-200);
      }

      &__top {
        display: flex;
        align-items: center;
      }
    }
  }
}

.with-sidebar > :first-child {
  flex-basis: 275px;
  flex-grow: 1;
}

.with-sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
}
