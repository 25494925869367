table {
  th {
    word-break: break-all;
    color: black;
    font-weight: 600 !important;
  }
  th:nth-last-child(-n + 1) {
    width: 1rem;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 1;
    visibility: hidden;
  }

  .show-on-hover {
    display: none;
  }

  tbody {
    tr {
      &:hover {
        background-color: var(--chakra-colors-gray-200);
      }
      position: relative;

      &.striped {
        background-color: var(--chakra-colors-gray-50);
        &:hover {
          background-color: var(--chakra-colors-gray-200);
        }
      }
      td {
        position: relative;
      }

      td:nth-last-child(-n + 1) {
        position: sticky;
        right: 0;
        top: 0;
        z-index: 1;
      }

      // td:nth-last-child(-n+3) {
      //     padding: 0 0.25rem;
      //  }

      &.hoverable:hover {
        .show-on-hover {
          padding: 0 var(--chakra-space-4);
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0%;
          background-color: transparent;
          border-radius: 1rem;
          z-index: 10;
        }
      }
    }
  }
}

.force-open {
  padding: 0 var(--chakra-space-4);
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: transparent !important;
  right: 0% !important;
  border-radius: 1rem !important;
  z-index: 20 !important;
}
